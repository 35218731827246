<template>
  <div>
    <a-card class="add-order" title="新建订单">
      <template #extra>
        <a-button type="link" class="p-0" size="small" @click="handlerClear">清空</a-button>
      </template>
      <div>
        <a-row>
          <a-col class="input-line" :span="24">
            <span class="input-line-title">收款账户：</span>
            <span>科技公司</span>
          </a-col>
        </a-row>

        <a-row class="mt-4">
          <a-col class="input-line" :span="24">
            <span class="input-line-title">取货方式：</span>
            <a-radio-group v-model="formState.order_type" 
                  @change="handleChangeOrderType">
              <a-radio :value="2">配送</a-radio>
              <a-radio :value="1">自提</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        
        <a-row class="mt-4">
          <a-col class="input-line mb-3 flex" :span="24" style="align-items: flex-start;">
            <span class="input-line-title" style="line-height:32px;">订单来源：</span>
            <a-radio-group v-model="isOnAccount" class="flex-1">
              <div class="flex items-center" style="height:32px;">
                <a-radio :value="1">第三方平台</a-radio>
                <template v-if="isOnAccount == 1">
                  <a-select v-model="subOnAccountType" class="ml-2 flex-1" placeholder="请选择挂账平台">
                    <a-select-option
                      v-for="item in subOnAccountPayTypeList"
                      :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                  </a-select>
                  <a-input class="ml-2 flex-1" placeholder="第三方平台的流水号"
                    v-model="artificial_pay_flow_no"></a-input>
                </template>
              </div>
              <div class="mt-2">
                <a-radio :value="2">非第三方平台（先下单、后选支付方式）</a-radio>
              </div>
            </a-radio-group>
          </a-col>
            
        </a-row>

        <template v-if="formState.order_type === 2">
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <span class="input-line-title"> <i class="require-i">*</i>收货地址： </span>
              <a-select class="flex-1" placeholder="请输入所在地址"
                  show-search
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  v-model="consignee_address"
                  @search="handleSearchAddress"
                  @change="handleChangeAddress">
                <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
              </a-select>
              <a-input class="ml-2 flex-1" placeholder="请录入单元/楼层/房号及其他说明信息"
                  v-model="consignee_address_detail"></a-input>
            </a-col>
          </a-row>
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>收货人： </span>
                <a-input class="flex-1" placeholder="收货人称呼"
                    v-model="formState.consignee_name" />
              </div>
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
                <a-input class="flex-1" placeholder="收货人手机号"
                    v-model="formState.consignee_phone" />
              </div>
              <div class="flex items-center" style="flex:3;">
                <span class="input-line-title"> <i class="require-i">*</i>收货时间： </span>
                <time-picker2 class="flex-1" :value.sync="delivery_time" />
              </div>
            </a-col>
          </a-row>
        </template>
        <template v-if="formState.order_type === 1">
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>取货人： </span>
                <a-input class="flex-1" placeholder="取货人称呼"
                    v-model="formState.recipient_name" />
              </div>
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
                <a-input class="flex-1" placeholder="取货人手机号"
                    v-model="formState.recipient_phone" />
              </div>
              <div class="flex items-center" style="flex:3;">
                <span class="input-line-title"> <i class="require-i">*</i>取货时间： </span>
                <time-picker2 class="flex-1" :value.sync="pickup_time" />
              </div>
            </a-col>
          </a-row>
        </template>

        <a-row class="mt-4">
          <a-col class="input-line flex items-center" :span="24">
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"><i class="require-i">*</i>下单人：</span>
              <a-input class="flex-1" placeholder="下单人称呼"
                  v-model="formState.place_order_name" />
            </div>
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"><i class="require-i">*</i>手机号：</span>
              <a-input class="flex-1" placeholder="下单人手机号"
                v-model.number="formState.place_order_phone" />
            </div>
            <div class="flex items-center" style="flex:3;">
              <a-button class="ml-4" type="primary" size="small" 
                  v-if="formState.order_type === 2" 
                  @click="handlerCopyUser">同收货人</a-button>
              <a-button class="ml-4" type="primary" size="small" 
                  v-if="formState.order_type === 1" 
                  @click="handlerCopyUser">同取货人</a-button>
            </div>
          </a-col>
        </a-row>

        <a-row class="mt-4">
          <a-col class="input-line" :span="24">
            <span class="input-line-title">下单备注：</span>
            <a-input class="flex-1" placeholder="请输入备注"
                v-model="formState.remarks" />
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col class="input-line" :span="24">
            <span class="input-line-title">内部备注：</span>
            <a-input class="flex-1" placeholder="内部备注（用户不可见）"
                v-model="formState.private_remarks" />
          </a-col>
        </a-row>

        <a-row class="mt-3">
          <a-col v-if="formState.order_type==1" class="input-line" :span="24">
            <span class="input-line-title"> <i class="require-i">*</i>门店： </span>
            <span v-if="pickup_shop_name" class="mr-3">{{ pickup_shop_name }}</span>
            <a-button type="primary" size="small" @click="handlerShowShopList">{{
              pickup_shop_name ? "更换" : "选择"
            }}</a-button>
          </a-col>
          <a-col v-if="formState.order_type==2" class="input-line" :span="24">
            <span class="input-line-title"> <i class="require-i">*</i>配送网点： </span>
            <div class="flex items-center" width="100%;">
              <span v-if="delivery_outlets_name" class="mr-3">{{ delivery_outlets_name }}</span>
              <a-button type="primary" size="small" @click="handlerShowShopList">{{
                delivery_outlets_name ? "更换" : "选择"
              }}</a-button>
              <template v-if="isCustomFreight">
                <span class="ml-10 mr-2">自定义运费</span>
                <a-input-number style="width:110px;" placeholder="运费"
                  v-if="isCustomFreight" :min="0" :step="1" 
                  v-model="formState.freight_amount" @change="updateTotal" /> 
                <span class="ml-1">元</span>
              </template>
              <template v-else>
                <span class="ml-10 mr-2">运费</span>
                <span>{{formState.freight_amount}} 元</span>
              </template>
            </div>
              
          </a-col>
        </a-row>

        <a-row class="mt-3">
          <a-col class="input-line" :span="8">
            <span class="input-line-title"> <i class="require-i">*</i>购买商品： </span>
            <a-button @click="handlerAddGoods" type="primary" size="small">添加</a-button>
          </a-col>
        </a-row>
      </div>

      <base-table
        class="mt-3"
        :customHeight="400"
        ref="orderAddRef"
        :columnsData="add_columns"
        rowKey="sku_id"
        :tableData="goodsList"
      >
        <template #number="{ record }">
          <a-input-number @change="changeGoodNumber" v-model="record.number"></a-input-number>
        </template>
        <template #operation="{ index }">
          <a-button type="link" @click="handlerDeleteSku(index)">删除</a-button>
        </template>
      </base-table>

      <!-- 蛋糕配件 -->
      <div class="mt-6 ml-3" v-if="cakeList.length">
        <span class="text-sm font-bold text-black">蛋糕配件</span>

        <div v-for="(item, i) in cakeList" :key="i * 100000 + item.sku_id">
          <div class="flex mt-2">
            <a-form-model layout="inline" ref="searchRef" class="flex" style="width:100%;">
              <a-form-model-item
                >{{ item.goods_name }}({{ item.flavor_name ? item.flavor_name + "|" : ""
                }}{{ item.specification_name }})</a-form-model-item
              >
              <a-form-model-item prop="birthday_candle" label="蜡烛">
                <birthday-candle :value.sync="item.birthday_candle"></birthday-candle>
              </a-form-model-item>
              <a-form-model-item prop="diners_number" label="餐具">
                <a-select :default-value="0" style="width: 80px" v-model="item.diners_number">
                  <a-select-option :value="index" v-for="(item, index) in 41" :key="index">{{ index }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item prop="plastic_tableware_number" label="一次性餐具">
                <a-select style="width: 80px" :default-value="0" v-model="item.plastic_tableware_number">
                  <a-select-option v-for="(item, index) in 41" :value="index" :key="index">{{ index }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="祝福语牌">
                <a-auto-complete v-model="item.birthday_card" :data-source="cardList"
                  style="width: 200px" placeholder="祝福语" />
              </a-form-model-item>

              <a-form-model-item label="贺卡" prop="birthday_greeting_card" class="flex flex-1">
                <a-textarea class="ml-2"
                      v-model="item.birthday_greeting_card"
                      :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>

      <div class="mt-5 ml-3">
        商品{{ formState.goods_amount }}元 + 运费{{ formState.freight_amount }}元 =
        <span class="text-lg font-bold">{{ formState.total }}</span> 元
      </div>
      <div class="mt-5 flex justify-center">
        <a-button @click="handlerCancel" type>取消</a-button>
        <a-button class="ml-4" type="primary" @click="handlerCreate">创建</a-button>
      </div>
    </a-card>

    <select-shop-modal
      v-if="isSelectShop"
      :formData="formState"
      :show.sync="isSelectShop"
      @ok="handleShopChanged"
    ></select-shop-modal>
    <select-outlets-modal
      v-if="isSelectOutlets"
      :formData="formState"
      :show.sync="isSelectOutlets"
      @ok="handlerOutletsChanged"
    />

    <add-goods-modal
      v-if="isSelectGoods"
      :show.sync="isSelectGoods"
      :outletsId="formState.outlets_id"
      :selectRows="goodsList"
      @ok="addGoodsOk"
    ></add-goods-modal>
  </div>
</template>

<script>
import selectOutletsModal from './components/select-outlets-modal.vue'
import selectShopModal from './components/select-shop-modal.vue'

import { add_columns, candleOptions } from './columns'
import AddGoodsModal from './components/add-goods-modal.vue'

import { getOrderPrice, createOrder, setOrderPayType } from '@/api/order/create'
import { getCityList } from "@/api/outlets"

import { subOnAccountPayTypeList, cardList } from "@/utils/type.js"
import { getMapPointList, getAdcode } from "@/utils/tMap.js"
import { checkAdcodes } from "@/utils/address.js"
import { searchShopDeliveryArea } from "@/utils/delivery.js"
import { validatePhone } from '@/hooks/use-form-rules.js'
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import TimePicker2 from '@/components/order/time-picker2.vue'

export default {
  components: { 
    selectShopModal, 
    selectOutletsModal, 
    AddGoodsModal, 
    TimePicker2, 
    BirthdayCandle 
  },
  data() {
    return {
      add_columns,
      candleOptions,
      subOnAccountPayTypeList,
      cardList,

      cityList: [],
      addressList: [],
      goodsList: [],
      cakeList: [],

      isTimePoint: false,
      pickup_time: [],
      delivery_time: [],

      formState: {
        order_type: 2, //配送方式
        city: {
          label: '', // 北京
          value: 0, // 110100000000
        },
        outlets_id: '',
        goods_amount: 0,
        freight_amount: 0,
        total: 0,
        place_order_name: "",
        place_order_phone: "",
        remarks: "",
        private_remarks: "",
        // 自提
        pick_up_goods_time_start: "",
        pick_up_goods_time_end: "",
        recipient_name: "",
        recipient_phone: "",
        // 配送
        delivery_outlets_id: 0,
        delivery_region_id: 0,
        consignee_lng: "",
        consignee_lat: "",
        delivery_time_start: "",
        delivery_time_end: "",
        consignee_name: "",
        consignee_phone: "",
        consignee_adcode: "",
        consignee_address: undefined,
      },
      
      consignee_address: undefined,
      consignee_address_detail: "",

      pickup_shop_id: '',
      pickup_shop_name: "",
      delivery_outlets_name: "",

      // 挂账
      isOnAccount: '',
      subOnAccountType: undefined,
      artificial_pay_flow_no: '',

      isSelectShop: false,
      isSelectOutlets: false,
      isSelectGoods: false,
      isCustomFreight: false,
    }
  },
  watch: {
    // goodsList: {
    //   deep: true,
    //   handler() {
    //     this.computedPrice()
    //   }
    // }
  },
  mounted () {
    this.initCityList()
  },
  methods: {
    async initCityList () {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    // 创建订单提交
    handlerCreate () {
      this.formState.consignee_address = this.consignee_address + (this.consignee_address_detail || "")
      const params = Object.assign({}, this.formState)

      if (this.validateForm()) {
        // 如果是配送单
        if (this.formState.order_type === 2) {
          params.delivery_time_start = this.delivery_time[0] + ' ' + this.delivery_time[1].split('-')[0]
          params.delivery_time_end   = this.delivery_time[0] + ' ' + this.delivery_time[1].split('-')[1]
        } else {
          params.pick_up_goods_time_start = this.pickup_time[0] + ' ' + this.pickup_time[1].split('-')[0]
          params.pick_up_goods_time_end   = this.pickup_time[0] + ' ' + this.pickup_time[1].split('-')[1]
        }

        let not_cake_list = this.goodsList.filter(el => el.goods_type != 3)
        params.goods_sku_data = JSON.stringify(not_cake_list.concat(this.cakeList))
        createOrder(params).then(res => {
          if (res.code === 0) {
            // 如果创建成功呢，提示并跳转到全部订单页
            if (res.data.submit_result === 'success') {
              let order_no = res.data.success_data.order_no

              // 挂账
              if (this.isOnAccount == 1) {
                setOrderPayType({
                  order_no: order_no,
                  pay_type: 30,
                  pay_subtype: this.subOnAccountType,
                  artificial_pay_flow_no: this.artificial_pay_flow_no,
                }).then(r => {
                  if (r.code == 0) {
                    this.$message.success('下单成功')
                    this.handlerJump(order_no)
                  }
                })
              } else {
                // 非挂账
                this.$message.success('下单成功，请尽快完成支付')
                this.handlerJump(order_no)
              }
            } else {
              this.$message.error(res.data.error_data.error_message)
              if (res.data.error_data.error_type === 3) {
                this.computedPrice()
              }
            }
          }
        })
      }
    },

    handlerShowShopList() {
      if (this.formState.order_type === 2) {
        if (this.formState.consignee_lat && this.formState.consignee_lng) {
          this.isSelectOutlets = true;
        } else {
          this.$message.warning("请先输入收货地址");
        }
      } else {
        this.isSelectShop = true;
      }
    },

    handleChangeOrderType(){
      this.formState.delivery_region_id = ''
      this.formState.delivery_outlets_id = ''
      this.delivery_outlets_name = ''
      this.formState.freight_amount = 0
      this.isCustomFreight = false

      if(this.formState.order_type == 1){
        this.formState.outlets_id = this.pickup_shop_id
      }else{
        this.formState.outlets_id = ''
      }
      this.computedPrice();
    },

    handleShopChanged(r) {
      if(r){
        this.pickup_shop_id = r.outlets_id;
        this.pickup_shop_name = r.outlets_name;
        this.formState.outlets_id = r.outlets_id;
      }

      this.computedPrice();
    },
    handlerOutletsChanged(r){
      if(this.formState.delivery_region_id){
        this.isCustomFreight = false
      }else{
        this.isCustomFreight = true
        this.formState.freight_amount = 0;
      }
      
      if(r){
        // this.formState.outlets_id = r.outlets_id
        // this.formState.delivery_outlets_id = r.outlets_id
        this.delivery_outlets_name = r.outlets_name
      }
      this.computedPrice();
    },

    handlerAddGoods() {
      if (!this.formState.outlets_id) {
        this.$message.warning('请先选择负责网点后再添加商品！')
        return
      }
      this.isSelectGoods = true
    },
    // 添加商品
    addGoodsOk (rows) {
      this.goodsList = rows.map(el => {
        if (!el.number) {
          el.number = 1
        }
        // 如果添加的有蛋糕，则放入蛋糕列表一份
        if (el.good_type === 3) {
          el.diners_number = 0
          el.plastic_tableware_number = 0
          el.birthday_candle = null
          el.birthday_card = ''
        }
        if (el.stock < 0) {
          el.status = "无货"
        } else if (el.stock < el.number) {
          el.status = "库存不足"
        }
        return el
      })
      this.updateCakeList()
      this.computedPrice()
    },

    changeGoodNumber () {
      this.updateCakeList()
      this.computedPrice()
    },

    // 删除
    handlerDeleteSku (index) {
      this.goodsList.splice(index, 1)
      this.updateCakeList()
      this.computedPrice()
    },

    updateCakeList () {
      let cakes = []
      this.goodsList.map(el => {
        if (el.goods_type == 3) {
          let arr = this.cakeList.filter(ck => ck.sku_id == el.sku_id)
          for (let i = 0; i < el.number; i++) {
            let cake = arr[i] || {}
            let item = Object.assign({}, el, {
              number: 1,
              diners_number: cake.diners_number,
              plastic_tableware_number: cake.plastic_tableware_number,
              birthday_candle: cake.birthday_candle,
              birthday_card: cake.birthday_card,
              birthday_greeting_card: cake.birthday_greeting_card,
            })
            cakes.push(item)
          }
        }
      })
      this.cakeList = cakes
    },

    // 计算订单价格
    computedPrice() {
      if (!this.goodsList.length) {
        // this.formState.freight_amount = 0
        this.formState.goods_amount = 0
      }
      this.updateTotal()

      if (!this.formState.outlets_id) return;
      if (!this.goodsList.length) return;

      // 添加完商品后，进行订单的价格计算
      const params = {
        outlets_id: this.formState.outlets_id,
        goods_sku_data: JSON.stringify(this.goodsList.map(el => {
          return {
            sku_id: el.sku_id,
            number: el.number || 1
          }
        }))
      }
      getOrderPrice(params).then(res => {
        if (res.code == 0) {
          let okHaxe, arr
          if (res.data.submit_result === 'error') {
            okHaxe = {}
            arr = res.data.error_data.goods_sku_list || []
            arr.map(el => {
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el => {
              if (okHaxe[el.sku_id]) {
                el.status = ""
                el.stock = okHaxe[el.sku_id].stock
                if (el.stock < 0) {
                  el.status = "无货"
                } else if (el.stock < el.number) {
                  el.status = "库存不足"
                }
              } else {
                el.status = "无货"
              }
              return el
            })
            this.$message.error(res.data.error_data.error_message)
            // return
          } else if (res.data.submit_result === 'success') {
            okHaxe = {}
            arr = res.data.success_data.goods_sku_list || []
            arr.map(el => {
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el => {
              if (okHaxe[el.sku_id]) {
                el.stock = okHaxe[el.sku_id].stock
                el.price = okHaxe[el.sku_id].price
                el.status = ""
              }
              return el
            })
            this.formState.goods_amount = res.data.success_data.goods_amount
            this.updateTotal()

          }
        }
      })
    },

    // 地址输入
    handleSearchAddress(value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item => {
            item.region = item.province + item.city + item.district
            return item
          })
        }
      })
    },

    async handleChangeAddress (value) {
      const item = this.addressList.find((item) => item.id === value)
      this.consignee_address = item.address + item.title
      this.formState.consignee_lat = item.location.lat
      this.formState.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)
      if (adcodesRes && adcodesRes.status == 0) {
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          this.formState.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
        } else {
          this.formState.consignee_adcode = adcodesRes.result.ad_info.adcode
        }
        for (let i in this.cityList) {
          let v = checkAdcodes(this.formState.consignee_adcode, this.cityList[i].adcode)
          if (v == 1 || v == 0) {
            this.formState.city.label = this.cityList[i].name
            this.formState.city.value = this.cityList[i].adcode
            break
          }
        }
        // 计算配送点
        let area = await searchShopDeliveryArea({
          lat: this.formState.consignee_lat,
          lng: this.formState.consignee_lng
        }, this.formState.consignee_adcode)
        if (area) {
          this.delivery_outlets_name = area.outlets_name;
          this.formState.outlets_id = area.outlets_id;
          this.formState.delivery_outlets_id = area.outlets_id;
          this.formState.delivery_region_id = area.delivery_region_id;
          this.formState.freight_amount = area.freight;
        } else {
          this.delivery_outlets_name = "";
          this.formState.outlets_id = '';
          this.formState.delivery_outlets_id = '';
          this.formState.delivery_region_id = '';
          this.formState.freight_amount = 0;
          this.$message.warning("超区，不在配送范围内");
        }
        this.computedPrice();
      }
    },

    updateTotal(){
      this.formState.total = (
              parseFloat(this.formState.goods_amount) +
              parseFloat(this.formState.freight_amount)
      ).toFixed(2)
    },

    handlerCopyUser(){
      if(this.formState.order_type == 1){
        this.formState.place_order_name = this.formState.recipient_name
        this.formState.place_order_phone = this.formState.recipient_phone
      }
      if(this.formState.order_type == 2){
        this.formState.place_order_name = this.formState.consignee_name
        this.formState.place_order_phone = this.formState.consignee_phone
      }
      this.$forceUpdate()
    },

    validateForm () {
      if (this.isOnAccount == '') {
        this.$message.warning('请选择订单来源')
        return false
      }
      if (this.isOnAccount == 1) {
        if (!this.subOnAccountType) {
          this.$message.warning('请选择挂账平台')
          return false
        }
        if (!this.artificial_pay_flow_no) {
          this.$message.warning('请填写第三方平台流水号')
          return false
        }
      }

      if (this.formState.place_order_phone && !validatePhone(this.formState.place_order_phone)) {
        this.$message.warning('下单人手机号格式有误')
        return false
      }
      // 如果是自提
      if (this.formState.order_type === 1) {
        if (!this.formState.recipient_name) {
          this.$message.warning('请输入取货人姓名')
          return false
        }
        if (!this.formState.recipient_phone) {
          this.$message.warning('请输入取货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.recipient_phone)) {
            this.$message.warning('取货人手机号格式有误')
            return false
          }
        }
        if (!this.pickup_time || !this.pickup_time.length) {
          this.$message.warning('请选择取货时间')
          return false
        }
      }
      if (this.formState.order_type === 2) {
        if (!this.formState.consignee_name) {
          this.$message.warning('请输入收货人姓名')
          return false
        }
        if (!this.formState.consignee_phone) {
          this.$message.warning('请输入收货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.consignee_phone)) {
            this.$message.warning('收货人手机号格式有误')
            return false
          }
        }
        if (!this.delivery_time || !this.delivery_time.length) {
          this.$message.warning('请选择配送时间')
          return false
        }
        if (!this.formState.consignee_lat) {
          this.$message.warning('请输入收货地址')
          return false
        }
      }
      // 如果有蛋糕信息
      // const cakes = this.cakeList
      // if (cakes && cakes.length) {
      //   for (let i = 0; i < cakes.length; i++) {
      //     if (!cakes[i].birthday_candle) {
      //       this.$message.warning('请选择蛋糕蜡烛')
      //       return false
      //     }
      //   }
      // }
      if (this.goodsList.length === 0) {
        this.$message.warning('请至少选择一个商品')
        return false
      }
      if (!this.formState.outlets_id) {
        this.$message.warning('请选择负责网点')
        return false
      }

      return true
    },

    handlerCancel () {
      this.handlerClear()
      this.$store.commit('tabsView/closeCurrentTab', this.$route)
      // 如果关闭的是当前页
      const tabsList = this.$store.getters['tabsView/tabsList']
      const currentRoute = tabsList[Math.max(0, tabsList.length - 1)]
      this.$router.push(currentRoute)
    },

    handlerJump(orderId) {
      this.handlerClear()
      this.$store.commit("tabsView/closeCurrentTab", this.$route);
      this.$router.push({ path: "/orderAudit", query: { order_no: orderId } });
    },

    handlerClear(){
      this.cityList = []
      this.addressList = []
      this.goodsList = []
      this.cakeList = []

      this.isTimePoint = false
      this.pickup_time = []
      this.delivery_time = []

      this.formState = {
        order_type: 2, //配送方式
        city: {
          label: '', // 北京
          value: 0, // 110100000000
        },
        outlets_id: '',
        goods_amount: 0,
        freight_amount: 0,
        total: 0,
        place_order_name: "",
        place_order_phone: "",
        remarks: "",
        private_remarks: "",
        // 自提
        recipient_name: "",
        recipient_phone: "",
        // 配送
        delivery_region_id: 0,
        delivery_outlets_id: '',
        consignee_lng: "",
        consignee_lat: "",
        consignee_name: "",
        consignee_phone: "",
        consignee_adcode: "",
        consignee_address: undefined,
      }

      this.consignee_address = undefined
      this.consignee_address_detail = ""


      this.pickup_shop_id = ''
      this.pickup_shop_name = ""
      this.delivery_outlets_name = ""
      
      // 挂账
      this.isOnAccount = ''
      this.subOnAccountType = undefined
      this.artificial_pay_flow_no = ''

      this.isSelectOutlets = false
      this.isSelectGoods = false
      this.isSelectShop = false
    },
  }
}
</script>

<style lang="less">
.input-line {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .require-i {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
}

.input-line-title {
  width: 100px;
  text-align: right;
}

.addr-region {
  margin-left: 10px;
  color: #ccc;
}

.thingtohide {
  animation: cssAnimation 0s ease-in 2s forwards;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
</style>
